<template>
	<div class="signup-card">
		<h4>FCTS 회원가입이 완료되었습니다.</h4>
		<h5>{{ this.id }} 님의 회원가입을 축하합니다.</h5>
		<div>
			<ul>
				<li>
					일반 회원 가입 후 기업회원으로 전환을 희망하시는 분은 [마이페이지 >
					회원정보] 메뉴에서 [기업회원 전환] 서비스를 이용해 주세요.
				</li>
				<li>기업회원 전환 후 FCTS의 모든 서비스를 이용하실 수 있습니다.</li>
			</ul>
		</div>
		<div class="button-group">
			<router-link to="/login"
				><button id="login-btn">로그인</button></router-link
			>
			<router-link to="/"
				><button id="home-btn" href="/">홈으로</button></router-link
			>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: '',
		},
	},
};
</script>

<style></style>
